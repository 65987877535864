<template>
    <div class="alarm-list">
        <Menu title="Alarm List"></Menu>
        <div class="container">
            <div class="alarm-list__section">
                <div v-for="data in widgetsData" :key="data.id" class="widget">
                    <div class="widget-title">{{ data.title }}</div>
                    <div class="widget-counter">
                        <div class="widget-counter__item --brands">
                            {{ data.currentSKU }}
                            <ArrowUpIcon class="widget-counter__item-trend --negative" v-if="data.currentSKU < data.lastSKU" :size="28"></ArrowUpIcon>
                            <ArrowDownIcon class="widget-counter__item-trend --positive" v-if="data.currentSKU > data.lastSKU" :size="28"></ArrowDownIcon>
                            <div class="widget-counter__last">
                                <span class="count" :class="setTrendClass(data)">{{ setLastValue(data) }}</span>
                                SKU
                            </div>
                        </div>
                    </div>
                    <div class="widget-description">{{ data.description }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Menu from "@/components/Menu/Menu.vue";
import ArrowUpIcon from 'vue-material-design-icons/ArrowUpThick.vue';
import ArrowDownIcon from 'vue-material-design-icons/ArrowDownThick.vue';

export default {
    name: "AlarmList",
    components: {
        Menu,
        ArrowUpIcon,
        ArrowDownIcon,
    },
    data() {
        return {
            widgetsData: [
                { id: 1, title: 'Low PSO Stock', description: 'Описание метрики', currentSKU: 430, lastSKU: 450 },
                { id: 2, title: 'Alarm SKU', description: 'Описание метрики', currentSKU: 2240, lastSKU: 1920 },
                { id: 3, title: 'OOS', description: 'Описание метрики', currentSKU: 1590, lastSKU: 1590 },
                { id: 4, title: 'Low rating score', description: 'Описание метрики', currentSKU: 780, lastSKU: 860 },
                { id: 5, title: 'Low product image score', description: 'Описание метрики', currentSKU: 1200, lastSKU: 910 },
            ]
        };
    },
    methods: {
        exportHandler() {
        },
        setTrendClass(data) {
            if (data.currentSKU > data.lastSKU) return '--negative';
            if (data.currentSKU < data.lastSKU) return '--positive';
            if (data.currentSKU === data.lastSKU) return '--equal';
        },
        setLastValue(data) {
            if (data.currentSKU > data.lastSKU) return `-${data.lastSKU}`;
            if (data.currentSKU < data.lastSKU) return `+${data.lastSKU}`;
            if (data.currentSKU === data.lastSKU) return `${data.lastSKU}`;
        }
    }
}
</script>

<style scoped lang="sass">
@import "/src/scss/resources"
.alarm-list
    &__section
        padding: 20px
        background-color: color(white)
        border-radius: 4px
        display: flex
        flex-wrap: wrap

    .additional-link
        color: #fff
        margin-right: 15px

.widget
    padding: 16px 32px
    background-color: color(white)
    border-radius: 4px
    border: 1px #E0E0E0 solid
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)
    width: 250px
    margin: 15px

    &-title
        height: 20px
        color: color(black-80)
        font-weight: 600

    &-description
        font-size: 12px
        margin-top: 10px
        color: color(gray-700)

    &-counter
        margin-top: 10px

        &__item
            position: relative
            display: flex
            flex-wrap: nowrap
            font-size: 16px
            font-weight: 600
            line-height: 1.5

            &.--brands
                color: color(black)
                font-size: 24px
                line-height: 1.37

            &-trend
                display: flex
                align-items: center

                &.--positive
                    color: color(green)

                &.--negative
                    color: color(red)

        &__last
            margin-left: auto
            font-size: 14px
            display: flex
            align-items: flex-end
            padding-bottom: 3px

            .count
                margin-right: 3px

                &.--positive
                    color: color(red)

                &.--negative
                    color: color(green)

                &.--other
                    color: color(gray-800)


</style>
