<template lang="pug">
.menu.container
	.flex.justify-between.items-center
		.menu__title(:class="{'--nestle': isNestle}")
			slot(name="title")
				| {{ title }}
		.menu__nav.flex.justify-end(v-if="isNestle")
			.menu__nav-wrap.flex.flex-wrap.justify-end
				.menu__item(
					v-for="(item, index) of nav"
					:key="index"
				)
					Link(
						:to="{name: item.path}"
						unstyled
					) {{ item.title }}
		slot

</template>

<script>
import Link from '@/components/Link/Link.vue'

export default {
	components: { Link },
	props: {
		title: String,
	},
	data() {
		return {
			nav: [
				{
					title: 'Portfolio Availability',
					path: 'Nestle.PortfolioAvailability',
				},
				{
					title: 'Share of search',
					path: 'Nestle.ShareOfSearch',
				},
				{
					title: 'Ratings and Reviews',
					path: 'Nestle.RatingsAndReviews',
				},
				{
					title: 'Taxonomy',
					path: 'Nestle.TaxonomyPlacement',
				},
				{
					title: 'Image Accuracy',
					path: 'Nestle.ImageAccuracy',
				},
				{
					title: 'Price Analysis',
					path: 'Nestle.PriceAnalysis',
				},
				{
					title: 'PSO Total',
					path: 'NestleLayout',
				},
				{
					title: 'PSO Details',
					path: 'Nestle.PSODetailsDashboard',
				},
				{
					title: 'Alarm List',
					path: 'Nestle.AlarmList',
				},
			]
		}
	},
	computed: {
		isNestle() {
			return this.$route.path.split('/')[1] === 'unilever'
		},
	},
}
</script>

<style lang="scss" scoped>
.menu {
	width: 100%;
	padding: 12px 24px;
	background-color: color(gray-800);

	&__title {
		min-height: 32px;
		color: color(white);
		font-size: 24px;
		line-height: 1.33;

		&.--nestle {
			width: 260px; // todo for demo only
		}
	}
	&__item {
		font-size: 14px;
		color: color(white);
		&:not(:last-of-type) {
			margin-right: 18px;
		}
	}
}
</style>
